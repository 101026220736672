import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import { imageUrlFor } from "../../../../../shared_ui/lib/image-url";
import clientConfig from "../../client-config";
import { buildImageObj } from "../lib/helpers";

// https://ogp.me

function SEO({ description, lang, meta, keywords, title, openGraphImage, bodyAttr }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription = description || (data.site && data.site.description) || "";
        const siteTitle = (data.site && data.site.title) || "";
        const siteAuthor = (data.site && data.site.author && data.site.author.name) || "";
        // Gtag
        const enableGtag =
          (data.site && data.site.analytics && data.site.analytics.enableGtag) || "";
        const gtagId = (data.site && data.site.analytics && data.site.analytics.gtagId) || "";

        // GA
        const enableGa = (data.site && data.site.analytics && data.site.analytics.enableGa) || "";
        const gaId = (data.site && data.site.analytics && data.site.analytics.gaId) || "";

        const metaImage =
          openGraphImage && openGraphImage.asset
            ? imageUrlFor(buildImageObj(openGraphImage), clientConfig)
                .width(1200)
                .url()
            : "";

        const pageTitle = title || siteTitle;

        return (
          <Helmet
            bodyAttributes={bodyAttr}
            htmlAttributes={{ lang }}
            title={pageTitle}
            titleTemplate={pageTitle === siteTitle ? siteTitle : `%s | ${siteTitle}`}
            meta={[
              {
                name: "google-site-verification",
                content: "7MfJFsxBVui5UlEBExUFeMW9-Q6g9fPgoaxwzvbqaV0",
              },
              {
                name: "description",
                content: metaDescription,
              },
              {
                property: "og:title",
                content: title,
              },
              {
                property: "og:description",
                content: metaDescription,
              },
              {
                property: "og:type",
                content: "website",
              },
              {
                property: "og:image",
                content: metaImage,
              },
              {
                name: "twitter:card",
                content: "summary",
              },
              {
                name: "twitter:creator",
                content: siteAuthor,
              },
              {
                name: "twitter:title",
                content: title,
              },
              {
                name: "twitter:description",
                content: metaDescription,
              },
            ]
              .concat(
                keywords && keywords.length > 0
                  ? {
                      name: "keywords",
                      content: keywords.join(", "),
                    }
                  : []
              )
              .concat(meta)}
          >
            {/* Unfortunately React-Helmet doesn't accept <>fragments</>, so the gtag scripts need to be seperate */}
            {enableGtag ? (
              <script async={true} src={`https://www.googletagmanager.com/gtag/js?id=${gtagId}`} />
            ) : (
              ""
            )}
            {enableGtag ? (
              <script>
                {`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', "${gtagId}");`}
              </script>
            ) : (
              ""
            )}
            {enableGa ? (
              <script>{`(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');ga('create', '${gaId}', 'auto');ga('send', 'pageview');`}</script>
            ) : (
              ""
            )}
          </Helmet>
        );
      }}
    />
  );
}

SEO.defaultProps = {
  lang: "en",
  meta: [],
  keywords: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      analytics {
        enableGtag
        gtagId
        enableGa
        gaId
      }
      openGraph {
        title
        description
      }
    }
  }
`;
